/* Footer.css */

.footer {
    
    background-color: black;
    color: white;
    padding: 20px;
    text-align: center;
    margin-top: 100px;
}
.yoursyle{
    margin-top: -55px;
}
.social-icons{
    display: flex;
}
.disstyle{
    color: yellow;
}

.social-icons img {
    width: 30px;
    /* Adjust size as needed */
    margin: 20px 10px;
    /* Adjust spacing as needed */
}

.disclaimer {
    margin-top: 10px;
    /* Adjust spacing as needed */
}

@media (max-width: 391px) {
    .social-icons {
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        /* Adjust spacing as needed */
    }

    .social-icons img {
    margin: 5px 10px;
        padding: 3px;
    }
}

@media(max-width:431px){
    .social-icons {
            display: flex;
            justify-content: center;
        }
}