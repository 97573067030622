/* Navbar.css */

.navbar {
    display: flex;
    
    align-items: center;
    padding: 1rem;
    background-color: #000000;
    color: #fff;
}

.logo {
    width: 128px;
    height: 96px;
    
}
.logo2 {
    margin-left: 40px;
}

.menu {
    display: flex;
    margin-inline-start: auto;
}


.btnstyle1 {

    background-color: #EE2316;
            font-size: 15px;
            font-weight: 600;
            margin-left: 1rem;
                
                padding: 0.5rem 2rem;
                border-radius: 12px;
                color: #fff;
                border: none;
                cursor: pointer;
}
.btnstyle2 {
    background-color: #E18E12;
    font-size: 15px;
    font-weight: 600;
    margin-left: 1rem;
       
        padding: 0.5rem 2rem;
        border-radius: 12px;
        color: #fff;
        border: none;
        cursor: pointer;
}
.btnstyle3 {
    background-color: #264CAF;
    font-size: 15px;
    font-weight: 600;
    margin-left: 1rem;
      
        padding: 0.5rem 2rem;
        border-radius: 12px;
        color: #fff;
        border: none;
        cursor: pointer;
}
/* Responsive styles */
@media (max-width: 430px) {
    .menu {
            display: flex;
            flex-direction: row;
    
            background-color: rgb(0, 0, 0);
        }
    .logo {
             width: 55px;
             height: 46px;
    
         }
    
    .logo2 {
             width: 95px;
             height: 37px;
    
             margin-left: 5px;
         }
    .navbar {
             display: flex;
             align-items: center;
             padding: 1rem;
             background-color: #000000;
             color: #fff;
         }
        .btnstyle1 {
    
            background-color: #EE2316;
            font-size: 9px;
            font-weight: 600;
            margin-left: 0.5rem;
            width: 56px;
            height: 18px;
    
            padding: 1px 3px;
            border-radius: 12px;
            color: #fff;
            border: none;
            cursor: pointer;
            margin-top: -13px;
        }
        .btnstyle2 {

            background-color:#E18E12 ;
                font-size: 9px;
                font-weight: 600;
                margin-left: 0.5rem;
                width: 56px;
                height: 18px;
            
                padding: 1px 3px;
                border-radius: 12px;
                color: #fff;
                border: none;
                cursor: pointer;
                margin-top: -13px;

        }

       .btnstyle3{
        background-color: #264CAF;
        
        font-size: 9px;
            font-weight: 600;
            margin-left: 0.5rem;
            width: 56px;
            height: 18px;
        
            padding: 1px 3px;
            border-radius: 12px;
            color: #fff;
            border: none;
            cursor: pointer;
            margin-top: -13px;
       } 
}

@media (max-width: 391px) {
    .menu {
        display: flex;
        flex-direction: row;
       
        background-color: #333;
    }
       
                .logo {
                width: 51px;
                    height: 42px;
        
                }
        
                .logo2 {
                width: 90px;
                    height: 32px;

                    margin-left: 5px;
                }

                        
        .navbar {
            display: flex;  
            align-items: center;
            padding: 1rem;
            background-color: #000000;
             color: #fff;
                                }

         .btnstyle1 {
    
             background-color: #EE2316;
             font-size: 8px;
             font-weight: 600;
             margin-left: 0.5rem;
        width: 43px;
            height: 13px;

             padding: 1px 3px;
             border-radius: 12px;
             color: #fff;
             border: none;
             cursor: pointer;
             margin-top: -13px;
         }
    
         .btnstyle2 {
             background-color: #E18E12;
             font-size: 8px;
             font-weight: 600;
             margin-left: 0.5rem;
            width: 43px;
            height: 13px;
             padding: 1px 3px;
             border-radius: 12px;
             color: #fff;
             border: none;
             cursor: pointer;
             margin-top: -13px;
         }
    
         .btnstyle3 {
             background-color: #264CAF;
             font-size: 8px;
             font-weight: 600;
             margin-left: 0.5rem;
        width: 43px;
            height: 13px;
             padding: 1px 3px;
             border-radius: 12px;
             color: #fff;
             border: none;
             cursor: pointer;
             margin-top: -13px;
         }

   
}