/* InfoComponent.css */

.rectangle-container {
    display: flex;
    align-items: center;
    justify-content: center;
   margin-top: 10vh;
   /* margin-bottom: 100px; */
    /* 100% of the viewport height */
}

.rectangle {
    width: 542px;
    height: 167px;
    background-color: #E18E12;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    color: #ffffff;
    /* Text color */
}

.text {
    text-align: center;
    margin-top: 5px;
    text-transform: capitalize;
}

.join {
    width: 302px;
    height: 31px;
    margin-top: 15px;
    padding: initial;
    font-size: 12px;
    background-color: black;
    margin-left: 1rem;
        width: 153px;
        padding: 0.5rem 2rem;
        border-radius: 12px;
        color: #fff;
        border: none;
        cursor: pointer;
    /* Adjust spacing between text and button */
}
@media(max-width:431px){
    .rectangle{
        width: 355px;
            height: 153px;
    }
        .text {
            font-size: small;
            text-align: center;
            margin-top: 5px;
            text-transform: capitalize;
        }


}
@media (max-width: 391px){
    .rectangle-container {

           
                height: 141px;
        
    }
        .rectangle {
            width: 90%;
            height: 141px;
            background-color: #264CAF;
            border-radius: 7px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            color: #ffffff;
            /* Text color */
        }

}
    
