.main1{

    display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding: 25px 5px;
}
.main2 {
    display: flex;
     flex-direction: column; 
     row-gap: 40px;
}
.main10 {
    display: flex;
     flex-direction: row; 
     row-gap: 25px;
}

.main3 {
    width: 80%;
    align-self: center;
    display: flex;
    flex-wrap: wrap;
    padding: 40px;
    row-gap: 40px;
    margin-top: 20px;
}
.main4{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.header1{
    color: #FFF;
    font-weight: 700;
}
.ptag1{
    color: #FFF;
     font-weight: 500; 
     font-size: 20px;
}

.text-center {
    color: #000;
    display: flex;
    justify-content: center;
    text-align: center;
    font-family: sans-serif;
    font-size: 40px;
    flex-grow: 1;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-transform: uppercase;
}
.sub {
    width: 1015px;
    display: inline;
    color: #000;
    font-family: sans-serif;
    padding: 10px;
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    font-style: normal;
    font-weight: 500;
    /* line-height: normal; */
}
.starDiv {
gap: 5px;
display: flex;
align-items: center;
justify-content: flex-start;
width: 33.33%


}
.starDiv h5 {
    margin-bottom: 0px;
}

.btnn {
    padding: 10px 8px;
    border-radius: 5px;
    
    background-color: red;
    color: rgb(249, 246, 246);
        text-align: center;
        font-family: sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
}
.img7{
    max-width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
}


.image-cont{
    
        background-color: #264CAF;
        padding: 50px 30px;
        display:  flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
       
}
.image-wrap{
    
    display: flex;
    column-gap: 15px;
    flex-direction:  row;
    align-items: center;
    justify-content: center;

}
@media (max-width: 431px)  {

    .text-center{
        font-size: 16px;
    }
    .sub{
        font-size: 10px;
    }

        .logo2 {
            width: 109px;
            height: 38px;
            margin-left: 5px;
        }

                .main2 {
                    display: flex;
                    flex-direction: column;
                    row-gap: 25px;
                }
                                .main3 {
                                    width: 90%;
                                    align-self: center;
                                    display: flex;
                                    flex-wrap: wrap;
                                    padding: 0px;
                                    row-gap: 25px;
                                    margin-top: 20px;
                                }


.btnn {
    width: 65px;
    height: 21px;
    color: #FFF;
    text-align: center;
    font-family: sans-serif;
    font-size: 9px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    
    padding: 6px;
    margin-left: 0px;

    
}

 .starDiv {
     gap: 1px;
     display: flex;
     align-items: center;
     justify-content: flex-start;
     width: 33.33%
 }

 .starDiv img {
     width: 16px;
     height: 18px;
     margin: 0 1px;
}
 .starDiv h5 {
     margin-bottom: 0px;
     font-size: 7px;
     font-weight: 550;
 }

 .image-cont {
     row-gap: 20px;

     flex-direction: column;

 }
}
@media (max-width: 392px) {

        .header1 {
            color: #FFF;
            font-weight: 600;
            font-size: 25px;
            }
        .ptag1 {
            color: #FFF;
            font-weight: 600;
            font-weight: 600;
            font-size: 17px;
        }
    
    .text-center {
        
        color: #000;
            font-family: sans-serif;
            font-size: 14px;
            font-style: normal;
            font-weight: 800;
            line-height: normal;
            text-transform: uppercase;
            margin-left:0px;
    
        }
                .starDiv {
                    gap: 1px;
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    width: 33.33%
                }
                .starDiv img {
                    width: 13px;
                    height: 13px;
                    margin: 0 1px;
                }

                
                .sub {
                    width: 303px;
                        height: 48px;
                        color: #000;
                        text-align: center;
                        font-family: sans-serif;
                        font-size: 8px;
                        font-style: normal;
                        display: contents;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: capitalize;
                        margin-left: 30px;
                    }

                .main2 {
                    display: flex;
                    flex-direction: column;
                    row-gap: 0px;
                }
                .btnmain{
                    width: 79px;
                        height: 20px;
                        flex-shrink: 0;
                }
                                .btnn {
                                    width: 59px;
                                    height: 18px;
                                    color: #FFF;
                                    text-align: center;
                                    font-family: sans-serif;
                                    
                                    font-size: 7px;
                                    font-style: normal;
                                    font-weight: 600;
                                    line-height: normal;
                                    /* padding: 7px; */
                                    padding: 6px;
                                    margin-left: 0px;
                                }


           .starDiv h5 {
               margin-bottom: 0px;
               font-size: 7px;
               font-weight: 600;
           }

                .image-cont {
                    row-gap: 20px;
        
                    flex-direction: column;
        
                }
                .main3{
                    width: 88%;
                        align-self: center;
                        display: flex;
                        flex-wrap: wrap;
                        row-gap: 15px;
                        margin-top: 20px;
                }


}
