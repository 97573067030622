/* PersonComponent.css */

body {
    margin: 0;
    font-family: 'Arial', sans-serif;
    overflow-x: hidden;
    /* Hide horizontal scrollbar for sliding window effect */
}
.boxer{
    background-color: #000;
}
/* .tex{
        text-align: center;
            color: white;
            font-family: sans-serif;
            font-weight: bold;
            font-size: 40px;
            padding: 43px;
            text-transform: uppercase;
} */

.imgsty {
    height: 50px;
    width: 50px;
    margin-top: -25px;
}

.grid-container {
    display: flex;
    flex-wrap: nowrap;
    /* Ensure cards stay in a single row */
    overflow-x: auto;
    /* Enable horizontal scrolling */
    scroll-snap-type: x proximity;
   
    
    /* Enable snap-scrolling */
    padding: 30px;
}

.grid-item {
    width: 323px;
    height: 254px;
    align-items: center;
    margin: 10px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 2px solid #ffeb3b;
    box-shadow: 0 4px 8px rgba(120, 240, 65, 0.1);
    transition: transform 0.3s ease;
    /* Add transition for smooth animation */
}

/* Add the following CSS to PersonComponent.css */



@keyframes scrollAnimation {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-100% * 4));
        /* Assuming you have 4 items, adjust based on the number of items */
    }
}

.grid-item {
    /* ... existing styles ... */
    flex: 0 0 calc(100% / 4 - 20px);
    box-shadow: 0 4px 8px rgba(205, 240, 4, 0.983);
    /* Orange shadow for each grid item */
}

.grid-item:hover {
    box-shadow: 0 8px 16px rgb(253, 110, 0);
    /* Increase shadow on hover */
    transform: scale(1.05);
    /* Add a slight zoom effect on hover */
}
@media (max-width: 440px) {
    .grid-container {
        overflow-x: auto;
            scroll-snap-type: x proximity;
        
    }

    .grid-item {
        transition: transform 0.5s ease;
        /* Adjust transition for sliding effect */
    }

    .grid-item.hidden {
        transform: translateX(-100%);
        /* Initially position cards outside the window */
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.tex {
    text-align: center;
    color: white;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 40px;
    padding: 43px;
    text-transform: uppercase;
}

@media (max-width: 391px) {
    .tex {
        color: white;
        font-size: 25px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .tex {
        
        font-size: 38px;
    }
}

@media (max-width: 431px) {
    .tex {
        font-size: 21px;
        font-weight: 600;
    }
}

.description {
    font-size: 14px;
    text-align: center;
    width: 295px;
    height: 108px;
    overflow: hidden;
}