.gridContainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;
    padding: 80px;
}

.gridItem {
    overflow: hidden;
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(7, 7, 7, 0.983);
    transition: box-shadow 0.3s ease, opacity 1s ease;
    /* Adding opacity transition */
    opacity: 0;
    animation: slideInleft 1s ease forwards;
    /* Adjusted animation duration */
}

@keyframes slideInleft {
    0% {
        opacity: 0;
        transform: translateX(25%);
    }

    100% {
        opacity: 1;
        transform: none;
    }
}

.gridItem:hover {
    box-shadow: 0 8px 16px rgba(246, 6, 6, 0.983);
}

.imageC {
    width: 100%;
    height: 270px;
    object-fit: fill;
    display: block;
    border-radius: 8px;
}

@media only screen and (max-width: 768px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        padding: 16px;
    }
}

@media (max-width: 431px) {
    .gridContainer {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 16px;
        padding: 16px;
    }

    .imageC {
        width: 100%;
        height: 100%;
    }
}