/* YourComponent.css */

.container {
    display: flex;
    width: 100%;
    max-width: 1330px;
    height: 100px;
    margin: 0 auto;
    background-color: #292929;
    border-radius: 30px;
}
.headersty{
    font-size: 25px;
}

.left-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    color: white;
    padding-left: 10px;
    /* Add padding for better appearance */
}

.right-content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    /* Add padding for better appearance */
}

button {
padding: 6px 15px;
    background-color: red;
    font-size: 20px;
    text-transform: capitalize;
    margin-left: 1rem;
        width: 153px;
        padding: 0.5rem 2rem;
        border-radius: 12px;
        color: #fff;
        border: none;
        cursor: pointer;
}

@media(max-width:431px)  {

    .headersty{
        font-size: 12px;
        padding-top: 5px;
    }
    button {

        width:113px;
        font-size: 12px;
        font-weight: 600;
    }
    .container{
        height: 78px;
    }
}


/* Media query for responsiveness */
@media (max-width: 391px) {
    .container {
            flex-direction: column;
                width: 95%;
                height: 56px;
                border-radius: 80px;
                margin-left: -9px px;
    }

   .headersty {
    color: #FFF;
        font-family: sans-serif;
        font-size: 8px;
        margin-top: 20px;
       
        font-weight: 600;
        
        line-height: normal;
        text-transform: uppercase;
        width: 142px;
        height: 12px;
        height: 15px;
        flex-direction: column;
        justify-content: center;
        flex-shrink: 0;
    }
   
   
    
   button {
        padding: 6px 15px;
            background-color: red;
            font-size: 8px;
            text-transform: uppercase;
            margin-left: 1rem;
            width: 83px;
            height: 27px;
            padding: 0.5rem 1rem;
            border-radius: 12px;
            color: #fff;
            border: none;
            cursor: pointer;
            margin-top: -40px;
   }
   .right-content {
       flex: 1;
       display: flex;    
   }
   .yoursyle {
       margin-top: -55px;
       margin-left: -10px;
   }
}