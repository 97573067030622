.head-text {
background: #ECECEC;


    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 40px 20px;
    margin-top:  20px;
    justify-content: center;
}

.text-on-image {
    color: #000;
        font-family: sans-serif;
        font-weight: bold;
      
        /* 110% */
}


/* ImageContainer.css */

.image-container {
    display: flex;
    justify-content: center;
        background-color:#EBEBEB; 
       
}

.image-wrapper {
 
    padding: 35px;
    /* Adjust as needed */
    box-sizing: border-box;
}

img {
    max-width: 100%;
    height: auto;
    display: block;
}
.h2,
h2 {
    font-weight: 600;
    font-size: 2rem;
}

@media only screen and (max-width: 768px) {
    .head-text {
        padding: 35px 18px;
    }

    .image-wrapper {
        padding: 25px;
    }
        .image-container {
            display: block;
            /* Change to block for mobile responsiveness */
            background-color: white;
        }
    
        .image-wrapper {
            padding: 35px;
            box-sizing: border-box;
        }
    
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
            /* Center the image */
        }
}

@media(max-width:431px){
    .image-container {
            display: flex;
            flex-direction: row;
    
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;
    
            gap: 0px;
            background-color: rgb(255, 255, 255);
            padding: 0px;
    
        }
                .head-text {
                    padding: 30px 15px;
                    background-color: white;
                }
        
                .image-wrapper {
                    padding: 20px;
                    width: 45%;
                    background-color: rgb(255, 255, 255);
                }
                                .h2,
                                h2 {
                                    font-size: 20px;
                                    font-weight: 600;
                                }

}

/* Media queries for even smaller screens */
@media only screen and (max-width: 391px) {
    .head-text {
        padding: 30px 15px;
    }

    .image-wrapper {
        padding: 20px;
        width: 45%;
        background-color: rgb(255, 255, 255);
    }
        .image-container {
            display: flex;
            flex-direction: row;
         
            width: 100%;
            justify-content: center;
            flex-wrap: wrap;

                gap: 0px;
                background-color: rgb(255, 255, 255);
                padding: 0px;
           
        }
    
        
    
        img {
            max-width: 100%;
            height: auto;
            display: block;
            margin: 0 auto;
            /* Center the image */
        }
.h2,
h2 {
    display: flex;
    width: 323px;
    height: 46px;
    flex-direction: column;
    justify-content: center;
    flex-shrink: 0;
    color: #000;
    text-align: center;
    font-family: sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
               
}