/* FeatureComponent.css */


body {
    margin: 0;
    font-family: 'Arial', sans-serif;
}
.min1{
    width: 33.33%;
    align-items:center;
    display: flex;
    flex-Direction: column;
}
.pptag1{
    font-size: 1rem;
    font-weight: bold;
}
.pptag2{
    font-size: 1rem;
     font-weight: 500;
      text-transform:lowercase;
}

.gr-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.gr-item {
    border-radius: 10px;
    width: 348.66px;
    padding: 20px 20px;
    background-color: #E18E12;
    margin: 10px;
    box-sizing: border-box;
    position: relative;
}

.image {
    width: 103.44px;
    height: 100px;
    display: block;
    margin: 0 auto;
}

.des {
    text-align: center;
    color: white;
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
}
.texte{

    text-align: center;
        color: white;
        background-color: black;
        font-family: sans-serif;
        font-weight: bold;
        font-size: 40px;
        padding: 15px;
        text-transform: uppercase;
}
@media(max-width: 768px) {
    .min1 {
            width: 100%;
            align-items: center;
            display: flex;
            flex-Direction: column;
        }
                 @media(max-width:431px) {
        
                     .h1,
                     h1 {
                         font-size: 20px;
                         font-weight: 700;
                     }

                                         .texte {
                    
                                             text-align: center;
                                             color: white;
                                             font-family: sans-serif;
                                             font-weight: bold;
                                             font-size: 40px;
                                             padding: 15px;
                                             text-transform: uppercase;
                                             background-color: black;
                                         }
                 }

 @media(max-width:391px){
    .h1,h1 {
            font-size: 20px;
            font-weight: 700;
        }


   @media(max-width:431px) {

    .h1,h1 {
            font-size: 20px;
            font-weight: 700;
        }
    }
 }       






}